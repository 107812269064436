import { useMemo } from 'react';
import { getCountryCode, useFlags } from 'common';
import { useIntl } from 'react-intl';

export const useIsOnDemandAccreditationEnabled = () => {
	const {
		learningOnDemandAccreditationChTemp14102024: onDemandAccreditationCh,
		learningOnDemandAccreditationAtTemp14102024: onDemandAccreditationAt
	} = useFlags();

	const { locale } = useIntl();
	const countryCode = getCountryCode(locale);

	const onDemandAccreditationEnabled = useMemo(() => {
		switch (countryCode) {
			case 'de':
				return true;
			case 'ch':
				return onDemandAccreditationCh;
			case 'at':
				return onDemandAccreditationAt;
			case 'gb':
				return true;
		}
	}, [countryCode, onDemandAccreditationCh, onDemandAccreditationAt]);

	return onDemandAccreditationEnabled;
};
